import { RootElement } from "./gatsby-root";
import { LanguageChangeWrapper } from "./src/i18n/language-change-wrapper";
import { ProjectSpecificPageWrapper } from "./src/project-specific/page-wrapper/project-sepcific-page-wrapper";
import { PageMeta } from "./src/site/core/meta";
import type { GatsbyBrowser } from "gatsby";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

export const wrapPageElement = ({ element, props }) => {
	return (
		<LanguageChangeWrapper pageLanguage={props.pageContext.lang}>
			<ProjectSpecificPageWrapper {...props}>
				<PageMeta {...props} />
				<div className={props.pageContext?.pageCssClass}>{element}</div>
			</ProjectSpecificPageWrapper>
		</LanguageChangeWrapper>
	);
};

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({
	element,
}) => {
	return <RootElement>{element}</RootElement>;
};
