// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Heading, Input, InputProps, Paragraph, Mdx } from "@cue/atoms";
import { useForm } from "@cue/hooks";
import { StyledComponent } from "@cue/theme";
import { AnalyticsTransformer, mustache } from "@cue/utility";
import styled, { CSSObject } from "@emotion/styled";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Auth } from "../../../cue-api/auth";

interface ResendAccesModalForm {
	email: string;
}
type States = "init" | "success" | "error";

export const ResendAccesModal: React.FC<
	StyledComponent<ResendAccesModalCSSOverride> & {
		fromAdhoc: boolean;
		closeModal?: () => void;
	}
> = (props) => {
	const { closeModal, fromAdhoc = false } = props;
	const auth = React.useMemo(() => new Auth(), []);
	const [state, setState] = React.useState<States>("init");
	const resendAccessRef = useRef<HTMLDivElement>(null);

	const { t } = useTranslation();
	const schema = yup
		.object({
			email: yup
				.string()
				.email(t("access.wrong-email"))
				.required(t("access.email-required")),
		})
		.required();

	const {
		form: { formState, handleSubmit },
		registerElement,
	} = useForm<ResendAccesModalForm>({
		schema,
		defaultValues: {
			email: "",
		},
	});

	useEffect(() => {
		const elm = resendAccessRef.current;

		const listener = (event: KeyboardEvent) => {
			if (event.code === "Enter") {
				handleSubmit(onSubmit)();
			}
		};
		elm?.addEventListener("keydown", listener);

		return () => {
			elm?.removeEventListener("keydown", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*  const goToRegForm = React.useCallback(() => {
    window.open(t('no-token.to-registration.uri').toString(), '_blank', 'noopener');
  }, [t]); */

	const onSubmit = async (values: ResendAccesModalForm) => {
		const resendResult = await auth.resendToken(values.email);
		if (resendResult.status === 204) {
			setState("success");
			AnalyticsTransformer.trackEvent({
				category: "register",
				type: "modal",
				action: "resend",
				data: { success: true },
			});

			// Close Modal automatically after 5 Seconds issue #159
			setTimeout(() => {
				if (closeModal) {
					closeModal();
				}
			}, 5000);
		} else {
			setState("error");
			AnalyticsTransformer.trackEvent({
				category: "register",
				type: "modal",
				action: "resend",
				data: { success: false },
			});
		}
	};
	/*   {mustache(props.mdx_before, user as unknown as Record<string, unknown>) || ''}
	 */

	return (
		<Container
			className="project-resend-access"
			data-test-id="cue-test-resend-access"
			ref={resendAccessRef}
		>
			<Heading type="h5">
				{t(`no-token.headline${fromAdhoc ? "-expert" : ""}`)}{" "}
			</Heading>
			{state === "init" && (
				<div className="project-resend-access-content">
					<div
						onClick={(e) => {
							if (e.currentTarget.nodeName === "A") {
								AnalyticsTransformer.trackEvent({
									category: "register",
									type: "modal",
									action: "open",
								});
							}
						}}
					>
						<Mdx linkTarget={"_blank"}>
							{mustache(t(`no-token.copy${fromAdhoc ? "-expert" : ""}`), {
								"no-token.to-registration.uri": t(
									"no-token.to-registration.uri",
								),
							})}
						</Mdx>
					</div>
					<div>
						<div className="project-resend-access-form">
							<div className="project-resend-access-form-input">
								{registerElement<InputProps>({
									element: Input,
									name: "email",
									props: {
										"data-test-id": "cue-test-resend-access-email",
										placeholder: t("no-token.email"),
										error:
											formState.isSubmitted &&
											formState.errors.email !== undefined,
									},
								})}
								<div className="project-resend-access-form-input-error">
									{formState.isSubmitted && formState.errors.email?.message}
								</div>
							</div>
							<Button
								onClick={handleSubmit(onSubmit)}
								data-test-id="cue-test-resend-access-submit"
								className="project-resend-access-submit"
							>
								{t("no-token.cta.label")}
							</Button>
						</div>
					</div>
				</div>
			)}
			{state === "success" && (
				<Paragraph>{t("no-token.cta.response")}</Paragraph>
			)}
			{state === "error" && <Paragraph>{t("no-token.cta.error")}</Paragraph>}
		</Container>
	);
};

const Container = styled.div``;

export type ResendAccesModalCSSOverride = {
	".project-resend-access"?: CSSObject;
	".project-resend-access-submit"?: CSSObject;
};
