import cmsLangConfig from "./cms-languages-config.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import i18n from "i18next";
import { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

// type CMSLanguageConfig = {
//   languages: { code: string; name: string; key: string }[];
//   defaultLanguage: string;
// };
const resources: Resource = {
	de: de,
	en: en,
	es: es,
};

// (cmsLangConfig as CMSLanguageConfig).languages.forEach(
//   ({ key }) => (resources[key] = await import(`./${key}.json`))
// );

// async function loadResources() {
//   // rewrite with for loop
//   for (const lang of cmsLangConfig.languages) {
//     resources[lang.key] = await import(`./${lang.key}.json`);
//   }
// }

// loadResources();

i18n.use(initReactI18next).init({
	resources,
	lng: "en",
	nsSeparator: false,
	supportedLngs: cmsLangConfig.languages.map(({ key }: { key: string }) => key),
	keySeparator: ".",
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

const languages = cmsLangConfig.languages;
const defaultLanguage = cmsLangConfig.defaultLanguage;
const availableLanguages = languages.map((lang) => lang.key);

export default i18n;
export { availableLanguages, defaultLanguage };
